<template>
  <form class="banner-input-container" @submit.prevent="emit('btnClick')">
    <div class="banner-input-icon mobile-hide">
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.5536 38.4326L29.2256 27.1046C31.9478 23.8366 33.3053 19.6449 33.0156 15.4015C32.726 11.1581 30.8115 7.18973 27.6705 4.32191C24.5295 1.4541 20.4038 -0.0923482 16.1516 0.00426926C11.8994 0.100887 7.84817 1.83313 4.84065 4.84065C1.83313 7.84817 0.100887 11.8994 0.00426926 16.1516C-0.0923482 20.4038 1.4541 24.5295 4.32191 27.6705C7.18973 30.8115 11.1581 32.726 15.4015 33.0156C19.6449 33.3053 23.8366 31.9478 27.1046 29.2256L38.4326 40.5536L40.5536 38.4326ZM3.05364 16.5536C3.05364 13.8836 3.8454 11.2735 5.3288 9.05344C6.8122 6.83338 8.92061 5.10305 11.3874 4.08127C13.8542 3.05948 16.5686 2.79214 19.1874 3.31304C21.8061 3.83394 24.2116 5.11969 26.0996 7.0077C27.9876 8.89571 29.2733 11.3012 29.7942 13.9199C30.3151 16.5387 30.0478 19.2531 29.026 21.7199C28.0042 24.1867 26.2739 26.2951 24.0538 27.7785C21.8338 29.2619 19.2237 30.0536 16.5536 30.0536C12.9744 30.0497 9.54296 28.6261 7.01208 26.0952C4.4812 23.5643 3.05761 20.1328 3.05364 16.5536Z"
          fill="#1E272F"
          fill-opacity="0.21"
        />
      </svg>
    </div>
    <input
      v-model="model"
      class="banner-input"
      :placeholder="placeholder"
      type="text"
      maxlength="100"
    />
    <button class="banner-input-btn btn-orange" type="submit">
      <slot name="btn-text" />
    </button>
  </form>
</template>

<script setup lang="ts">
  const emit = defineEmits(['btnClick']);
  const model = defineModel<string>({default: ''});

  defineProps({
    placeholder: {
      type: String,
      default: () => '',
    },
  });
</script>

<style lang="scss">
  @import '@/assets/styles/variables';

  .banner-input {
    border: none;
    outline: none;
    height: 3rem;
    box-shadow: none;
    font-size: 22px;
    width: 100%;
    margin-left: 32px;
    background: transparent;

    @media screen and (width <= 728px) {
      margin-left: 1rem;
      font-size: 12px;
    }

    &-container {
      display: flex;
      box-shadow: 0 38px 202px 0 #1e272f2b;
      align-items: center;
      padding-left: 2rem;
      border-radius: $border-radius;
      width: 830px;
      height: 98px;

      @media screen and (width <= 728px) {
        height: 45px;
        padding-left: 0;
      }
    }

    &-btn {
      font-size: 22px;
      padding: 37px 54px;
      position: relative;
      white-space: nowrap;
      max-height: 98px;
      z-index: 1;
      border-radius: $border-radius;
      border: none;

      &:hover {
        opacity: 0.75;
        cursor: pointer;
      }

      @media screen and (width <= 728px) {
        max-height: 45px;
        font-size: 14px;
        padding: 14px 31px;
      }
    }

    &-filter {
      margin-right: 24px;
      padding-left: 10px;
      cursor: pointer;

      &-title {
        font-size: 18px;
        font-weight: 500;
      }

      &-checkbox {
        margin-top: 34px;

        label {
          font-size: 16px !important;
          font-weight: 300;
        }

        &-item:not(:first-child) {
          margin-top: 16px;
        }

        padding-bottom: 20px;
        border-bottom: 1px solid #eceded;
      }

      .dropdown-list {
        width: 318px;
        height: 522px;
        left: -90px;
        top: 80px;
        padding-left: 27px;
        padding-right: 29px;
        z-index: 6;
      }

      svg {
        fill: #1e272f;
      }

      &-active {
        svg {
          fill: #287eff;
        }
      }

      &-city {
        .dropdown-icon {
          padding-top: 18px;
          padding-bottom: 18px;
          border-bottom: 1px solid #eceded;
        }

        .dropdown-list {
          top: 30px !important;
          left: -10px !important;
          width: 245px !important;
          height: 251px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }

      &-footer {
        margin-top: 24px;

        &-radius {
          font-size: 14px;
          font-weight: 300;

          &-input {
            margin-top: 12px;
            border-radius: 9px;
            background: #f2f2f2;
            width: 101px;
            height: 36px;
            padding: 11px;
            font-size: 12px;

            &-inactive {
              color: rgb(30 39 47 / 29%);
            }
          }
        }

        &-slider {
          margin-top: 12px;
        }

        .vue-slider {
          height: 6px !important;
        }

        .vue-slider-dot {
          width: 20px !important;
          height: 20px !important;

          &-handle {
            background: #ff6b17 !important;
            border: 2px solid #fff !important;
          }
        }

        &-actions {
          display: flex;
          align-items: center;
          gap: 5px;
          margin-top: 19px;

          .btn-dark {
            width: 157px;
            height: 34px;
            font-size: 12px;
            padding: 0;
          }

          .btn-secondary {
            width: 92px;
            height: 34px;
            font-size: 12px;
            padding: 0;
          }
        }
      }
    }
  }
</style>
